.#{$rt-namespace}__toast {
  position: relative;
  //box-sizing: border-box;
  //margin-bottom: 1rem;
  //padding: 8px;
  //border-radius: 1px;
  //box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
  display: flex;
  justify-content: space-between;
  max-height: $rt-toast-max-height;
  overflow: hidden;
  //border:none;
  //font-family: $rt-font-family;
  &-body {
    //margin: auto 0;
    //flex: 1 1 auto;
    //padding-bottom: $rt-toast-progressbar-height;
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
  }
}
