.full-screen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(../../assets/auth-image.jpg);
  background-size: cover;
}

.content {
  width: 55%;
  background-color: white;
  padding-left: 50px;
}

.title {
  width: 45%;
  background-color: rgba(15, 63, 98, 0.6);
  padding-left: 80px;
}

.title-text {
  line-height: 1.1;
}