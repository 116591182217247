.coaching-icon {
  color: #FF6262;
}

.athletes-icon {
  color: #62B4FF;
}

.billing-icon {
  color: #FFBD62;
}

.support-icon {
  color: #25C68E;
}