@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url(./Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: url(./Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: url(./Roboto-Light.ttf);
}

