// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        -2: -($spacer * .5),
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3),
                        6: ($spacer * 4.5),
                        xxs: $spacer / 5,
                        xs: $spacer / 2.5,
                        sm: $spacer / 2,
                        md: $spacer,
                        lg: $spacer * 2,
                        half-gutter: $grid-gutter-width * 0.5,
                        gutter: $grid-gutter-width,
                        double-gutter: ($grid-gutter-width * 2)
                ),
                $spacers
);
