@import "../../../theme/theme";
.#{$rt-namespace}__toast-container {
    z-index: $rt-z-index;
    -webkit-transform: translate3d(0, 0, #{$rt-z-index}px);
    position: fixed;
    //padding: 4px;
    width: $rt-toast-width;
    box-sizing: border-box;
    color: #fff;
    &--top-left {
        top:0;
        left: map-get($spacers, half-gutter);
    }
    &--top-center {
        top:0;
        left: 50%;
        transform: translateX(-50%);
    }
    &--top-right {
        top:0;
        right: map-get($spacers, half-gutter);
    }
    &--bottom-left {
        bottom:0;
        left: map-get($spacers, half-gutter);
    }
    &--bottom-center {
        bottom:0;
        left: 50%;
        transform: translateX(-50%);
    }
    &--bottom-right {
        bottom:0;
        right: map-get($spacers, half-gutter);
    }
}

@media #{$rt-mobile} {
    .#{$rt-namespace}__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0;
        &--top-left,
        &--top-center,
        &--top-right {
            top: 0;
            transform: translateX(0);
        }
        &--bottom-left,
        &--bottom-center,
        &--bottom-right {
            bottom: 0;
            transform: translateX(0);
        }
        &--rtl{
          right: 0;
          left: initial;
        }
    }
}
