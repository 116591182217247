@font-face {
  font-family: 'Akrobat ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: url(./Akrobat-Black.ttf);
}

@font-face {
  font-family: 'Akrobat Bold';
  font-style: normal;
  font-weight: normal;
  src: url(./Akrobat-SemiBold.ttf);
}

@font-face {
  font-family: 'Akrobat Regular';
  font-style: normal;
  font-weight: normal;
  src: url(./Akrobat-Regular.ttf);
}

@font-face {
  font-family: 'Akrobat Light';
  font-style: normal;
  font-weight: normal;
  src: url(./Akrobat-Light.ttf);
}

