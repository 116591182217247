.mobile-view {
  display: none;
}

.desktop-view {
  display: block;
}

@media only screen and (max-width: 600px) {
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }
}

.mobile-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sad-face {
  width: 80px;
}