/*****************************************************************************************************************************/
/****** WARNING! THIS FILE IS AUTO-GENERATED. DO NOT EDIT IT MANUALLY OR OTHERWISE YOUR CHANGES WILL BE OVERWRITTEN!! ******/
/*****************************************************************************************************************************/

.HeaderExtraBold {
  font-family: 'Akrobat ExtraBold';
  font-weight: 1000;
}

.HeaderBold {
    font-family: 'Akrobat Bold';
    font-weight: 800;
}
    
.HeaderRegular {
    font-family:  'Akrobat Regular';
    font-weight: 600;
}
    
.HeaderLight {
    font-family: 'Akrobat Light';
    font-weight: 300;
}
    
.BodyBold {
    font-family: 'Roboto Bold';
    font-weight: 600;
}
    
.BodyRegular {
    font-family: 'Roboto Regular';
    font-weight: 400;
}
    
.BodyLight {
    font-family: 'Roboto Light';
    font-weight: 300;
}
    
body{
    font-family: undefined;
}
